<template lang="pug">
  .insight-all-skills
    .insight-all-skills__action-filter-bar
      div.insight-all-skills--export-button(style={marginRight: 'auto', paddingLeft: '1px'})
          button.btn.btn-clean-hover-primary.icon-btn(@click="openExport")
            i.fa.fa-file-export
            | {{ 'pages.administration.export' | translate }}
      span.insight-all-skills--filter-appied {{filterAppliedLength}} {{ 'pages.insights._filters_settings_applied' | translate }}
      span.active-dark-text.insight-all-skills--reset(@click="onResetAll") {{ 'ui.buttons.reset' | translate }}
      v-btn(@click="toggleFilterModal") {{ 'ui.buttons.filter' | translate }}
    div.insight-all-skills_preloader(v-show="loading")
      v-preloader
    div(v-show="!loading")
      graphics(ref="graphics"
        :chartData="barChartData"
        :maxChartValue="maxChartValue"
        :selectedBar="selectedBar"
        @on-select="onSelectBar")
      filter-modal(ref="filterModal"
        :show="showFilterModal"
        :departments="filterOptions.department"
        :locations="filterOptions.location"
        :skills="filterOptions.badge"
        @on-apply="onApplyFilters"
        @on-reset="onResetAll"
        @on-close="toggleFilterModal")
      detail-drawer
</template>

<script>

import { mapActions, mapGetters } from 'vuex'
import DetailDrawer from './modals/DetailDrawer'
import FilterModal from '../modals/FilterModal'
import Graphics from '../common/Graphics'

export default {
  name: 'AllSkills',

  components: {
    FilterModal,
    Graphics,
    DetailDrawer
  },

  async mounted () {
    await this.loadgeneralStats()
    await this.loadInsights()
    await this.loadDepartments()
    this.setUpdateRef(this.$refs.graphics.update)

    this.filterOptions.location = this.changeFilterStructure(this.removeRef(this.locationsTree[0] ? this.locationsTree[0].children : []))
    this.filterOptions.badge = this.addParents(this.removeRef(this.skillsTree))
    this.loading = false
  },

  destroyed () {
    this.resetFilters()
  },

  data: () => ({
    loading: true,
    showFilterModal: false,
    filterOptions: {
      department: [],
      location: [],
      badge: []
    }
  }),

  methods: {
    ...mapActions('insightsGoals', [
      'resetFilters',
      'setFilters',
      'loadgeneralStats',
      'selectBar',
      'setUpdateRef',
      'loadInsights'
    ]),

    openExport () {
      window.open(process.env.VUE_APP_BASE_URL + '/insights/goals/export', '_blank')
    },

    removeRef (data) {
      return JSON.parse(JSON.stringify(data))
    },

    toggleFilterModal () {
      this.showFilterModal = !this.showFilterModal
    },

    onResetAll () {
      this.resetFilters()
      let reset = (array) => {
        for (let i in array) {
          array[i].checked = false
          reset(array[i].children || [])
        }
      }
      for (let key in this.filterOptions) {
        reset(this.filterOptions[key])
      }
      this.showFilterModal = false
      this.$refs.graphics.resetData()
    },

    onSelectBar (data) {
      this.selectBar(data)
    },

    changeFilterStructure (array, parents = []) {
      for (let i in array) {
        array[i].parents = parents
        array[i].name = array[i].data.name
        array[i].id = array[i].data.id
        if (array[i].children && array[i].children.length) {
          let childParents = [array[i], ...parents]
          this.changeFilterStructure(array[i].children, childParents)
        }
      }
      return array
    },

    addParents (array, parents = []) {
      for (let i in array) {
        array[i].parents = parents
        if (array[i].children && array[i].children.length) {
          let childParents = [array[i], ...parents]
          this.addParents(array[i].children, childParents)
        }
      }
      return array
    },

    onApplyFilters () {
      let filters = {
        departmentIds: [],
        locationIds: [],
        badgeIds: []
      }
      let onFilter = (array, key) => {
        for (let i in array) {
          if (array[i].checked) {
            filters[key + 'Ids'].push(array[i].id)
          }
          onFilter(array[i].children || [], key)
        }
      }
      for (let key in this.filterOptions) {
        onFilter(this.filterOptions[key], key)
      }
      this.setFilters(filters)
      this.showFilterModal = false
      this.$refs.graphics.resetData()
    },

    loadDepartments () {
      let departments = this.removeRef(this.departments[0] ? this.departments[0].children : []).filter(el => !el.isLeaf)

      let filterChildren = (child) => {
        let _children = child.filter(el => !el.isLeaf)
        for (let i in _children) {
          if (!_children[i].children.length) {
            continue
          } else {
            _children[i].children = filterChildren(_children[i].children)
          }
        }
        return _children
      }

      for (let i in departments) {
        departments[i].children = filterChildren(departments[i].children || [])
      }
      this.filterOptions.department = this.changeFilterStructure(departments)
    }
  },

  computed: {
    ...mapGetters('insightsGoals', [
      'barChartData',
      'selectedBar',
      'maxChartValue',
      'skillsTree',
      'filters'
    ]),

    ...mapGetters('insightsAllSkills', [
      'skillsTree'
    ]),

    ...mapGetters('organization', [
      'departments'
    ]),
    ...mapGetters('locations', [
      'locationsTree'
    ]),

    filterAppliedLength () {
      let count = 0
      if (this.filters) {
        Object.values(this.filters).forEach(el => { count += el.length })
      }
      return count
    }
  },

  watch: {
    departments (value) {
      this.loadDepartments()
    },

    locationsTree (value) {
      this.filterOptions.location = this.changeFilterStructure(this.removeRef(this.locationsTree[0].children))
    },

    skillsTree (value) {
      this.filterOptions.badge = this.addParents(this.removeRef(this.skillsTree))
    }
  }

}
</script>

<style lang="scss">

</style>
